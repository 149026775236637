@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 300;
  src: url('~assets/fonts/Sofia Pro Light Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 400;
  src: url('~assets/fonts/Sofia Pro Regular Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 500;
  src: url('~assets/fonts/Sofia Pro Medium Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 600;
  src: url('~assets/fonts/Sofia Pro Semi Bold Az.otf') format('opentype');
}

@font-face {
  font-family: 'Sofia Pro';
  font-style: normal;
  font-weight: 700;
  src: url('~assets/fonts/Sofia Pro Bold Az.otf') format('opentype');
}

body {
  background: $bg;
  font-family: 'Sofia Pro';
  color: $dark;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

.app-input {
  width: 100%;
  min-width: 180px;
  max-width: 400px;
  &_pointer .q-field__control .q-field__native {
    cursor: pointer;
  }
  &_datepicker {
    min-width: 120px;
  }
  .q-field__control {
    background-color: $highlight;
    border-radius: $app-border-radius;
    padding-left: 14px;
    padding-right: 14px;
  }
  .q-field__label {
    color: $additional;
  }
  &.q-field--readonly .q-field__control:before {
    border: 1px solid $additional;
  }
}

.app-dialog {
  min-width: 350px;
}

.text-label {
  color: $additional;
}

#q-app {
  a {
    color: $additional;
    text-decoration: none;
  }

  .app-link {
    color: $dark;
    &:hover {
      transition: 0.3s;
      color: $additional;
    }
  }

  .q-checkbox__label {
    color: $additional;
  }

  .text-500 {
    font-weight: 500;
  }

  .text-600 {
    font-weight: 600;
  }

  .text-300 {
    font-weight: 300;
  }

  .text-md {
    font-size: $space-2;
    font-weight: 500;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }

  .flex-horizontal {
    overflow-x: auto;
    flex-wrap: nowrap;
    padding-bottom: $space-1;
  }

  .title {
    font-size: 22px;
    font-weight: 500;
  }

  .subtitle {
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
  }

  .q-item .app-input {
    max-width: 500px;
  }

  .app-page {
    padding: $space-3;
  }

  .item-icon {
    border-radius: 50%;
    background-color: $additional;
    padding: 10px;
  }

  .drawer-item {
    border-radius: $app-border-radius;
    padding: 0;
    margin: $space-2;
    &.q-router-link--exact-active {
      background-color: $highlight;
    }
  }

  .drawer-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    .logout {
      margin: 32px auto;
      border-radius: $app-border-radius;
    }
    .copryrights {
      font-size: 11px;
      margin: 22px 0;
    }
  }

  .auth-btn {
    text-transform: capitalize;
    width: 100%;
  }

  .initials .q-avatar__content {
    padding-top: 4px;
  }

  .btn-more {
    line-height: 1;
    font-weight: 400;
    padding: 0;
    &::v-deep {
      .q-btn__wrapper {
        padding: 0;
      }
    }
  }
  .pagination {
    background-color: #fff;
    border-radius: $app-border-radius;
    padding: $space-1;
    box-shadow: 0px -1px 15px rgba(0, 0, 0, 0.04),
      0px 4px 15px rgba(0, 0, 0, 0.08);
  }
}

.not-available {
  font-size: 12px;
  color: $additional;
}

.default-card {
  height: calc(100vh - 100px);
  color: $dark;
}

.default-table {
  height: calc(100vh - 100px);

  color: $dark;
  .table {
    position: relative;
  }
  .scroll-indicator {
    opacity: 0;
    height: 0;
  }
  tbody tr:last-of-type td {
    border-bottom: 1px solid $grey;
  }
  thead tr th {
    position: sticky;
    z-index: 1;
  }
  thead tr:first-child th {
    top: 0;
  }

  /* this is when the loading indicator appears */
  &.q-table--loading thead tr:last-child th {
    /* height of all previous header rows */
    top: 48px;
  }

  thead tr {
    background-color: #dff4f8;

    &:first-child th:last-child {
      min-width: 180px;
    }

    &:last-child {
      top: 48px;
    }
    th {
      position: sticky;
      z-index: 1;
    }
  }
  td {
    text-align: left;
  }
  td:first-of-type {
    text-align: left;
  }
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.loading-main {
  @media (min-width: $breakpoint-sm-max) {
    left: 300px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  will-change: opacity;
}

.tooltip-item {
  position: relative;
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
}

.tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 4px;
  background-color: $grey;
  border-radius: 4px;
  &:after {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $grey;
  }
}
